import React, {Suspense, lazy} from "react";
import { useMediaQuery, useTheme} from '@mui/material';
import './App.scss';
import historyR from "./helpers/history";
import type {BrowserHistory} from "history";
import {BrowserRouterProps, Router, Route, Routes} from "react-router";

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import 'typeface-roboto'

import {getColor} from "./assets/colors";
import LoadingProgress from "./components/LoadingProgress";
import {useAppSelector} from "./redux/hooks";

const Login = lazy(() => import('./views/cuenta/login'));
const Register = lazy(() => import('./views/cuenta/register'));
const ForgotPassword = lazy(() => import('./views/cuenta/ForgotPassword'));
const Solicitar = lazy(() => import('./views/solicitud/Solicitar'));
const EstadoCliente = lazy(() => import('./views/EstadoCliente'));
const Reports = lazy(() => import('./views/reports'));


const Dashboard = lazy(() => import('./layouts/layout'));


export default function App(){
  const auth = useAppSelector(state => state.auth)

  const {uiColor} = auth.user || {uiColor:"#17a2b8"};
  const smVewport = useMediaQuery(useTheme().breakpoints.down('sm'));

  //console.log(themeObj)
  // const hist = createBrowserHistory();
  let theme = createTheme({
      components: {
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
        MuiDialogContent:{
          styleOverrides:{
            root: {
              padding: "16px",
            }
          }
        },

        MuiDialogActions:{
          styleOverrides:{
            root: {
              borderTop: "1px solid #e6e6e6",
              padding: "8px 16px",
              "& button:not(.MuiIconButton-root)":{
                padding: "4px 11px",
              }
            }
          }
        },
      ...(!smVewport? {
        MuiInputLabel:{
          styleOverrides:{
            root: {
              "&:not([class*='-outlined'])":{
                lineHeight: 1,
                position: "initial !important",
                transform: "none !important",
                color: "inherit !important",
                marginBottom: "7px !important",
                fontSize: "0.95rem",
              }
            },
          }
        },
        MuiInputBase:{
          styleOverrides:{
            root:{
              background: "white",
              "&:not([class*='MuiOutlinedInput'])":{
                border: "1px solid #e6e6e6",
                marginTop: "0px !important",
                "& [class*='-input']:not([class*='Pagination'])":{ //:not(select)
                  padding: "6px 15px",
                },
                "&:before, &:after":{
                  display:"none !important",
                }
              }
            },
            inputAdornedStart:{
              paddingLeft:"5px !important",
            }
          }
        },
        MuiInputAdornment:{
          styleOverrides:{
            positionStart:{
              "&>p":{
                background: "#ededed",
                height: 35,
                width: 35,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",

              },
              "&.MuiInputAdornment-sizeSmall":{
                "&>p":{
                  height: 31,
                  width: 31,
                }
              }
            }
          }
        }
      }:{}),
      MuiButton: {
        styleOverrides:{
          root: {
            borderRadius: 2,
            // lineHeight:1,

          },
          startIcon:{
            marginBottom: 1,
          }
        }
      },
      MuiTableRow: {
        styleOverrides:{
          head: {
            height: 34,
          },
          root: {
            //height: 44,
          },
          footer:{
            cursor: "auto !important",
          }
        }
      },
      MuiTableCell: {
        styleOverrides:{
          root:{
            "&:not([class*='sizeSmall'])":{
              height: 44,
              padding: ".5rem",
              boxSizing: "border-box",
            },
          },
          head: {
            color: uiColor,
            borderBottom: uiColor + " 2px solid",
            background: "#eeeeee",
          },
          body: {
          },
          footer:{
            fontSize: "0.85rem"
          }
        }
      },
    },
      palette: {
       // mode: 'dark',
        ...getColor(uiColor),
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
      },
      mixins: {
        toolbar: {
          minHeight: 50,
        }
      },
    }, esES);

    return (
      <div className="App">
        <StyledEngineProvider injectFirst>
          <ThemeProvider  theme={theme}>
            <Suspense fallback={<LoadingProgress/>}>
              <BrowserRouter basename={"/"}>

                <Routes>
                  <Route path="/login" element={<Login/>}/>
                  <Route path="/register/:noPayment?" element={<Register/>}/>

                  <Route path="/reports/:type/:id?" element={<Reports/>}/>
                  <Route path={"/forgotPasswordConfirm"} element={<ForgotPassword/>}/>
                  <Route path={"/forgotPassword"} element={<ForgotPassword/>}/>


                  <Route path="/solicitarCredito/:user" element={<Solicitar/>}/>
                  <Route path={"/estadoCliente/:user/:prestamoId?"} element={<EstadoCliente/>}/>


                  {auth.isAuthenticated? (
                      // <Suspense fallback={<LoadingProgress/>}>
                        <Route path={"*"} element={<Dashboard />}/>
                      // </Suspense>
                  ):(
                      <>
                        <Route path="*" element={auth.status === "loading"? <LoadingProgress/>:<Login/>}/>
                        {/*<Route path="*" element={<Login/>}/>*/}
                      </>
                  )}


                </Routes>
              </BrowserRouter>
            </Suspense>
          </ThemeProvider >
        </StyledEngineProvider>
      </div>
    );

}

export function BrowserRouter({basename, children}: BrowserRouterProps) {

  let historyRef = React.useRef<BrowserHistory>();
  if (historyRef.current == null) {
    historyRef.current = historyR;
  }

  let history = historyRef.current;
  let [state, setState] = React.useState({
    action: history.action,
    location: history.location
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
      <Router
          // @ts-ignore
          basename={basename}
          children={children}
          location={state.location}
          navigationType={state.action}
          navigator={history}
      />
  );
}
